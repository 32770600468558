//
// forced.scss
//

.forceNoPadding
{
    padding: 0 !important;
}

.forceCenter
{
    text-align: center !important;
}

.centerBlock
{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
