//
// public_download.scss
//

.infoLine
{
    padding: 5px 15px 5px 15px;
}

.infoLine .infoCell
{
    margin-top: auto;
    margin-bottom: auto;
}

.infoLine.infoLineTop
{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.infoLine.infoLineBottom
{
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.infoLine.infoLine1
{
    background-color: #F0F0F0;
}
.infoLine.infoLine2
{
    background-color: #F5F5F5;
}
.infoLine.infoLine3
{
    background-color: #F0F0F0;
}
.infoLine.infoLine4
{
    background-color: #F5F5F5;
}
.infoLine.infoLine5
{
    background-color: #F0F0F0;
}
.infoLine.infoLine6
{
    background-color: #F5F5F5;
}
.infoLine.infoLine7
{
    background-color: #F0F0F0;
}
.infoLine.infoLine8
{
    background-color: #F5F5F5;
}
.infoLine.infoLine9
{
    background-color: #F0F0F0;
}
.infoLine.infoLine10
{
    background-color: #F5F5F5;
}



.infoLine .infoLine.infoLine1
{
    background-color: #DFDFDF;
}
.infoLine .infoLine.infoLine2
{
    background-color: #CFCFCF;
}
.infoLine .infoLine.infoLine3
{
    background-color: #DFDFDF;
}
.infoLine .infoLine.infoLine4
{
    background-color: #CFCFCF;
}
.infoLine .infoLine.infoLine5
{
    background-color: #DFDFDF;
}
.infoLine .infoLine.infoLine6
{
    background-color: #CFCFCF;
}
.infoLine .infoLine.infoLine7
{
    background-color: #DFDFDF;
}
.infoLine .infoLine.infoLine8
{
    background-color: #CFCFCF;
}
.infoLine .infoLine.infoLine9
{
    background-color: #DFDFDF;
}
.infoLine .infoLine.infoLine10
{
    background-color: #CFCFCF;
}
